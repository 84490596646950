import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-review-comparision',
  templateUrl: './review-comparision.component.html',
  styleUrls: ['./review-comparision.component.scss']
})
export class ReviewComparisionComponent {

  originalDataSource: string;
  changedDataSource: SafeHtml;
  constructor(
    private dialogref: MatDialogRef<ReviewComparisionComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.originalDataSource = this.replaceSpecialCharacters(data.originalDataSource);
      this.changedDataSource = this.sanitizer.bypassSecurityTrustHtml(data.changedDataSource);
   
  }

  ngOnInit() {
 
  }

  onClose() {
    this.dialogref.close(null);
  }


  replaceSpecialCharacters(content: string): string {
    return content.replace(/ /g, '&nbsp;').replace(/\n/g, '<br>');
  }
  syncScroll(sourceElement: HTMLElement, targetElement: HTMLElement): void {
    if(sourceElement.scrollTop !== targetElement.scrollTop) {
      targetElement.scrollTop = sourceElement.scrollTop;
    }
  }


}
