export const profile = {
    sFirstName: {
        required: 'Please enter First Name',
    },
    sLastName: {
        required: 'Please enter Last Name',
    }
};
export const BotCreate = {
    sAssistantName: {
        required: 'Please enter Assistant Name',
    }
};
export const Login = {
    sEmail: {
        required: 'Please enter Email',
        valid: 'Please enter valid Email',
    },
    sPwd: {
        required: 'Please enter Password',
        valid: 'Please enter valid Password'
    }
}
export const ForgotPWD = {
    sEmail: {
        required: 'Please enter Email',
        valid: 'Please enter valid Email',
    },
    sConsentCode: {
        required: 'Please enter OTP',
        valid: 'Please enter valid OTP',
    },
    sNewPwd: {
        required: 'Please enter Password',
    }
}