import { FormGroup } from '@angular/forms';

// Provide all set of validation messages here
const VALIDATION_MESSAGES = {};

export class GenericValidator {
  constructor(private validationMessages: { [key: string]: { [key: string]: string } } = VALIDATION_MESSAGES) { }
  processMessages(container: FormGroup): { [key: string]: string } {
    var messages:any ={};
    for (const controlKey in container.controls) {
      if (container.controls.hasOwnProperty(controlKey)) {
        const controlProperty = container.controls[controlKey];
          if (controlProperty instanceof FormGroup) { 
                const childMessages = this.processMessages(controlProperty);
                Object.assign(messages, childMessages);
          }
         else {
          if (this.validationMessages[controlKey]) {
              messages[controlKey] ='';
              if ((controlProperty.dirty || controlProperty.touched) && controlProperty.errors) {
                Object.keys(controlProperty.errors).map(messageKey => {
                  if (this.validationMessages[controlKey][messageKey]) {
                    messages[controlKey] += this.validationMessages[controlKey][messageKey] + ' ';
                  }
                });
              }
          }
        }
      }
    }
    return messages;
  }
}


